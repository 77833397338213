<template>
	<div class="page-crm-person">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h3 class="my-0">
					<router-link :to="`/${j.slug}/people`">CRM</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" />
					{{ person ? person.name : $route.params.id }}
				</h3>
			</div>
			<div class="col-auto">
				<button class="btn btn-outline-primary btn-sm me-3" disabled>
					<font-awesome-icon :icon="['fas', 'pencil']" /> Edit
				</button>
			</div>
		</div>

		<div v-if="states.person === 'loaded'" class="card">
			<div class="card-body">
				<div class="row align-items-center">
					<div class="col-auto">
						<person-avatar :person="person" :size="80" :tooltip="false" :preview="false"></person-avatar>
					</div>
					<div class="col">
						<h2 class="my-0">
							<template v-if="person.first_name">{{ person.first_name }} {{ person.last_name }}</template>
							<template v-else-if="person.name">{{ person.name }}</template>
							<template v-else>{{ person.anonymous_name }}</template>
						</h2>
						<p v-if="person.bio" class="text-neutral-500 mt-1 mb-0">{{ person.bio }}</p>
					</div>
				</div>

				<hr class="my-3 bg-primary-100" />

				<div class="row">
					<div class="col-md-6">
						<h5 class="subtitle">Personal details</h5>

						<dl class="row mb-0">
							<dt class="col-sm-4">Email</dt>
							<dd class="col-sm-8">
								<span v-if="person.email">
									<a :href="`mailto:${person.email}`">{{ person.email }}</a>
									<font-awesome-icon
										:icon="['fas', 'copy']"
										@click="copyToClipboard(person.email)"
										class="ms-2 text-neutral-400 cursor-pointer"
									/>
								</span>
								<small v-else class="text-neutral-300">-</small>
							</dd>

							<dt class="col-sm-4">Phone</dt>
							<dd class="col-sm-8">
								<span v-if="person.phone">
									<a :href="`tel:${person.phone}`">{{ person.phone }}</a>
									<font-awesome-icon
										:icon="['fas', 'copy']"
										@click="copyToClipboard(person.phone)"
										class="ms-2 text-neutral-400 cursor-pointer"
									/>
								</span>
								<small v-else class="text-neutral-300">-</small>
							</dd>

							<dt class="col-sm-4">Address</dt>
							<dd class="col-sm-8">
								<span v-if="person.address_json">
									<a
										:href="
											`https://www.google.com/maps/place/${formatAddress(person.address_json, {
												country: true,
											})}`
										"
										target="_blank"
										>{{ formatAddress(person.address_json) }}</a
									>
									<font-awesome-icon
										:icon="['fas', 'copy']"
										@click="copyToClipboard(formatAddress(person.address_json))"
										class="ms-2 text-neutral-400 cursor-pointer"
									/>
								</span>
								<small v-else class="text-neutral-300">-</small>
							</dd>

							<dt class="col-sm-4">Date of Birth</dt>
							<dd class="col-sm-8">
								<span v-if="person.dob">
									{{ person.dob | dateLocal('default', { timeZone: 'UTC', dateStyle: 'medium' }) }}
								</span>
								<small v-else class="text-neutral-300">-</small>
							</dd>
						</dl>
					</div>

					<div class="col-md-6">
						<div class="d-flex align-items-center justify-content-between mb-3">
							<h5 class="subtitle my-0">Roles &amp; Departments</h5>

							<span
								class=""
								role="button"
								data-bs-toggle="modal"
								data-bs-target="#modal-add-to-department"
								>➕ Add to department</span
							>
						</div>

						<ul v-if="person.roles.filter(r => r.role !== 'CITIZEN').length" class="nicer-list mb-0">
							<li
								v-for="role in person.roles.filter(r => r.role !== 'CITIZEN')"
								:key="role.department_id"
							>
								<span v-if="role.title">{{ role.title }} &middot; </span>
								<router-link v-if="departments" :to="`/departments?department=${role.department_id}`">{{
									departments.find(d => d.id === role.department_id).name
								}}</router-link>
							</li>
						</ul>
						<p v-else class="text-neutral-400"><i>Not in any department, Resident role.</i></p>
					</div>
				</div>
			</div>
		</div>

		<div v-else-if="states.person === 'loading'" class="text-center py-4">
			<div class="spinner-border" role="status"></div>
		</div>

		<div class="modal fade" id="modal-add-to-department" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<form @submit.prevent="addToDepartment">
						<div class="modal-header">
							<h5 class="modal-title">Add to department</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-row mb-3">
								<label class="form-label" for="new-department-id"
									>Department <small class="text-danger-400">*</small></label
								>

								<select
									class="form-select"
									id="new-department-id"
									v-model="newDepartment.department_id"
									required
								>
									<option :value="null">Select department</option>
									<option
										v-for="department in activeDepartments.filter(
											d => !personInDepartments.includes(d.id)
										)"
										:key="department.id"
										:value="department.id"
									>
										{{ department.name }}
									</option>
								</select>
							</div>

							<div class="form-row mb-3">
								<label class="form-label" for="new-department-title">Title</label>

								<input
									type="text"
									class="form-control"
									v-model="newDepartment.title"
									id="new-department-title"
									placeholder="e.g. Project Manager"
								/>
							</div>

							<div class="form-row">
								<label class="form-label" for="new-department-role">Role</label>

								<select class="form-select" id="new-department-role" v-model="newDepartment.role">
									<option value="WORKER">Member</option>
									<option value="EDITOR">Department Admin</option>
									<option value="ADMIN">HeyGov Admin</option>
								</select>
							</div>
						</div>

						<div class="modal-footer text-end">
							<button class="btn btn-primary" :disabled="states.addToDepartment === 'loading'">
								Add
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { Modal } from 'bootstrap'
import { mapGetters, mapState } from 'vuex'
import heyGovApi, { handleResponseError } from '@/api.js'
import PersonAvatar from '@/components/PersonAvatar.vue'
import { copyToClipboard, formatAddress } from '@/utils.js'

export default {
	name: 'Person',
	components: { PersonAvatar },
	data() {
		return {
			states: {
				person: 'loading',
				addToDepartment: 'idle',
			},
			person: null,

			$modalAddToDepartment: null,
			newDepartment: {
				department_id: null,
				role: 'WORKER',
				title: '',
			},
		}
	},
	computed: {
		...mapGetters(['activeDepartments']),
		...mapState(['j', 'departments']),
		personInDepartments() {
			return this.person ? this.person.roles.map(r => r.department_id) : []
		},
	},
	created() {
		this.$store.dispatch('loadDepartments')
		this.loadPerson()
	},
	mounted() {
		this.$modalAddToDepartment = new Modal(document.getElementById('modal-add-to-department'))
	},
	methods: {
		copyToClipboard,
		formatAddress,

		loadPerson() {
			this.states.person = 'loading'

			heyGovApi.get(`/${this.j.slug}/people/${this.$route.params.id}`).then(({ data }) => {
				this.person = data
				this.states.person = 'loaded'
			}, handleResponseError(`Error loading person data ({error})`))
		},

		addToDepartment() {
			this.states.addToDepartment = 'loading'

			heyGovApi
				.post(`/${this.j.slug}/departments/${this.newDepartment.department_id}/add-member`, {
					person_id: this.$route.params.id,
					role: this.newDepartment.role,
					title: this.newDepartment.title,
				})
				.then(({ data }) => {
					this.person.roles.push({
						department_id: data.department_id,
						role: data.role,
						title: data.title,
					})

					const department = this.departments.find(d => d.id === data.department_id)

					Vue.toasted.success(`"${this.person.last_name}" is added to "${department.name}"`)
					this.$modalAddToDepartment.hide()
				}, handleResponseError(`Error adding person to department ({error})`))
				.finally(() => {
					this.states.addToDepartment = 'idle'
				})
		},
	},
}
</script>
