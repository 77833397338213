<template>
	<div class="page-crm">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h3 class="my-0">CRM</h3>
			</div>
			<div class="col-auto">
				<button class="btn btn-outline-primary btn-sm me-3" disabled>
					<font-awesome-icon :icon="['fas', 'file']" /> Import
				</button>

				<button class="btn btn-primary btn-sm btn-big-icon" disabled>
					<font-awesome-icon :icon="['fas', 'plus-circle']" /> Add
				</button>
			</div>
		</div>

		<!-- <div class="row mb-3">
			<div class="col-3">
				<div class="card text-center">
					<div class="card-body p-2">
						<p class="mb-2 text-neutral-400">HeyGov accounts</p>
						<h5 v-if="stats" class="display-6 my-0">{{ stats.people.toLocaleString() }}</h5>
						<h5 v-else class="display-6 my-0 text-neutral-500">-</h5>
					</div>
				</div>
			</div>
			<div class="col-3">
				<div class="card text-center">
					<div class="card-body p-2">
						<p class="mb-2 text-neutral-400">Households</p>

						<h5 class="display-6 my-0 text-neutral-500">-</h5>
					</div>
				</div>
			</div>
			<div class="col-3">
				<div class="card text-center">
					<div class="card-body p-2">
						<p class="mb-2 text-neutral-400">Population</p>

						<h5 v-if="j.population_count" class="display-6 my-0">
							{{ j.population_count.toLocaleString() }}
						</h5>
						<h5 v-else class="display-6 my-0 text-neutral-500">-</h5>
					</div>
				</div>
			</div>
			<div class="col-3">
				<div class="card text-center">
					<div class="card-body p-2">
						[map]
					</div>
				</div>
			</div>
		</div> -->

		<div class="card mb-4">
			<div class="card-body">
				<!-- <ul class="nav nav-tabs on-parent mb-3">
					<li class="nav-item">
						<router-link class="nav-link active" :to="`/${j.slug}/people`">All</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" :to="`/${j.slug}/people`">Garbage pickup</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" :to="`/${j.slug}/people`">Pet owners</router-link>
					</li>
					<li class="nav-item">
						<router-link class="nav-link" :to="`/${j.slug}/people`">Wildlife & Nature</router-link>
					</li>
					<li class="nav-item">
						<button class="nav-link text-neutral-400">
							<font-awesome-icon :icon="['fas', 'plus']" /><span class="ms-1 show-on-hover">
								Add list/topic/segment</span
							>
						</button>
					</li>
				</ul> -->

				<div class="row align-items-center g-3">
					<div class="col-auto">
						<font-awesome-icon :icon="['fas', 'filter']" />
					</div>

					<div class="col-sm-6 col-lg">
						<input
							type="search"
							class="form-control form-control-sm"
							v-model="filters.q"
							placeholder="Search by name or email"
						/>
					</div>

					<div class="col-sm-6 col-lg">
						<select class="form-select form-select-sm" v-model="filters.role">
							<option :value="null">📁 Any role</option>
							<option
								v-for="(role, roleId) in roles"
								:key="`jurisdiction-person-${roleId}`"
								:value="roleId"
								>{{ role.name }}
							</option>
							<option value="CITIZEN">Resident</option>
						</select>
					</div>

					<div class="col-sm-6 col-lg">
						<select class="form-select form-select-sm" v-model="filters.department_id">
							<option :value="null">📁 Any department</option>
							<option
								v-for="department in activeDepartments"
								:key="`jurisdiction-department-${department.id}`"
								:value="department.id"
								>{{ department.name }} ({{ department.people.length }})
							</option>
						</select>
					</div>

					<div class="col-sm-6 col-lg">
						&nbsp;
					</div>

					<!-- <div class="col-auto">
						<div class="btn-group">
							<button
								class="btn btn-sm btn-outline-danger"
								data-bs-toggle="modal"
								data-bs-target="#modal-send-message"
							>
								<font-awesome-icon :icon="['fas', 'bolt']" class="me-1" /> Send emergency alert
							</button>
							<button
								class="btn btn-sm btn-outline-primary"
								data-bs-toggle="modal"
								data-bs-target="#modal-send-message"
							>
								<font-awesome-icon :icon="['fas', 'paper-plane']" class="me-1" /> Send message
							</button>
						</div>
					</div> -->

					<div class="col-sm-6 col-lg-auto">
						<div class="dropdown">
							<button
								class="btn btn-sm btn-outline-dark"
								type="button"
								data-bs-toggle="dropdown"
								title="Customize columns for people list"
							>
								<font-awesome-icon :icon="['fas', 'columns']" class="me-1" />
							</button>
							<ul class="dropdown-menu">
								<li>
									<label class="dropdown-item"><input type="checkbox" checked disabled /> Name</label>
								</li>
								<li>
									<label class="dropdown-item"
										><input type="checkbox" v-model="tableColumns" value="email" /> Email</label
									>
								</li>
								<li>
									<label class="dropdown-item"
										><input type="checkbox" v-model="tableColumns" value="phone" /> Phone</label
									>
								</li>
								<li>
									<label class="dropdown-item"
										><input type="checkbox" v-model="tableColumns" value="bio" /> Bio</label
									>
								</li>
								<li>
									<label class="dropdown-item"
										><input type="checkbox" v-model="tableColumns" value="dob" /> Date of
										birth</label
									>
								</li>
								<li>
									<label class="dropdown-item"
										><input type="checkbox" v-model="tableColumns" value="address" /> Address</label
									>
								</li>
								<li v-if="j.features.includes('issues')">
									<label class="dropdown-item"
										><input type="checkbox" v-model="tableColumns" value="threads" /> 311
										requests</label
									>
								</li>
								<li v-if="j.features.includes('forms')">
									<label class="dropdown-item"
										><input type="checkbox" v-model="tableColumns" value="form_requests" /> Form
										submissions</label
									>
								</li>
								<li v-if="j.features.includes('venues')">
									<label class="dropdown-item"
										><input type="checkbox" v-model="tableColumns" value="venue_bookings" /> Venue
										reservations</label
									>
								</li>
								<li v-if="paymentsFeature">
									<label class="dropdown-item"
										><input type="checkbox" v-model="tableColumns" value="payments" />
										Payments</label
									>
								</li>
								<li>
									<label class="dropdown-item"
										><input type="checkbox" checked disabled /> Added at</label
									>
								</li>
							</ul>
						</div>
					</div>
				</div>

				<hr class="bg-primary-50" />

				<div class="card-table mb-3">
					<table class="table table-hover">
						<thead>
							<tr class="">
								<th class="p-0">
									<label class="p-2 ps-3"
										><input type="checkbox" @change="selectAll" id="people-select-all"
									/></label>
								</th>
								<th class="ps-0 pe-1" width="40"></th>
								<th @click="sortTable('first_name', 'asc')" class="hover cursor-pointer">
									Name
									<small v-if="sorting.order_by === 'first_name'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th @click="sortTable('email', 'asc')" class="hover cursor-pointer">
									Email
									<small v-if="sorting.order_by === 'email'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th
									v-if="tableColumns.includes('phone')"
									@click="sortTable('phone', 'asc')"
									class="hover cursor-pointer"
								>
									Phone
									<small v-if="sorting.order_by === 'phone'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th v-if="tableColumns.includes('bio')" @dblclick="states.dev = !states.dev">Bio</th>
								<th
									v-if="tableColumns.includes('dob')"
									@click="sortTable('dob', 'asc')"
									class="hover cursor-pointer"
								>
									DOB
									<small v-if="sorting.order_by === 'dob'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th v-if="tableColumns.includes('address')">Address</th>
								<th
									v-if="tableColumns.includes('threads') && j.features.includes('issues')"
									@click="sortTable('count_threads')"
									class="hover cursor-pointer"
								>
									<img src="https://files.heygov.com/assets/icon-hey311.png" height="16" />
									<small v-if="sorting.order_by === 'count_threads'" class="ms-1">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th
									v-if="tableColumns.includes('form_requests') && j.features.includes('forms')"
									@click="sortTable('count_form_requests')"
									class="hover cursor-pointer"
								>
									<img src="https://files.heygov.com/assets/icon-heylicense.png" height="16" />
									<small v-if="sorting.order_by === 'count_form_requests'" class="ms-1">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th
									v-if="tableColumns.includes('venue_bookings') && j.features.includes('venues')"
									@click="sortTable('count_venue_bookings')"
									class="hover cursor-pointer"
								>
									<img src="https://files.heygov.com/assets/icon-heyreserve.png" height="16" />
									<small v-if="sorting.order_by === 'count_venue_bookings'" class="ms-1">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th
									v-if="paymentsFeature && tableColumns.includes('payments')"
									@click="sortTable('count_payments')"
									class="hover cursor-pointer"
								>
									<img src="https://files.heygov.com/assets/icon-heygovpay.png" height="16" />
									<small v-if="sorting.order_by === 'count_payments'" class="ms-1">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th @click="sortTable('created_at')" class="hover cursor-pointer">
									Added
									<small v-if="sorting.order_by === 'created_at'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr
								v-for="person in people"
								:key="`member-${person.id}`"
								class="on-parent"
								:class="{ 'bg-primary-50': peopleSelected.includes(person.id) }"
							>
								<td class="p-0">
									<label class="p-2 pt-3 ps-3"
										><input type="checkbox" v-model="peopleSelected" :value="person.id"
									/></label>
								</td>
								<td class="ps-0 pe-1">
									<person-avatar
										:person="person"
										:preview="false"
										:size="40"
										:tooltip="false"
									></person-avatar>
								</td>
								<td>
									<p class="mb-0">
										<router-link :to="`/${j.slug}/people/${person.id}`"
											><template v-if="person.first_name"
												>{{ person.first_name }} {{ person.last_name }}</template
											><template v-else-if="person.name">{{ person.name }}</template
											><span v-else class="text-neutral-500">{{
												person.anonymous_name
											}}</span></router-link
										>
									</p>
									<span
										v-for="role in person.jurisdiction_person.filter(j => j.role !== 'CITIZEN')"
										:key="role.department_id"
										class="badge bg-neutral-50 mt-1 me-1"
										><span v-if="role.title" class="text-neutral-500 me-1">{{ role.title }}</span>
										<span
											class="text-neutral-400"
											v-if="departments && departments.find(d => d.id === role.department_id)"
											>{{ departments.find(d => d.id === role.department_id).name }}</span
										></span
									>
								</td>

								<td>
									<span v-if="person.email">{{ person.email }}</span>
									<small v-else class="text-neutral-300">-</small>
								</td>

								<td v-if="tableColumns.includes('phone')">
									<span v-if="person.phone">{{ person.phone }}</span>
									<small v-else class="text-neutral-300">-</small>
								</td>

								<td v-if="tableColumns.includes('bio')">
									<span v-if="person.bio">{{ person.bio }}</span>
									<small v-else class="text-neutral-300">-</small>
								</td>

								<td v-if="tableColumns.includes('dob')">
									<span v-if="person.dob">{{
										person.dob
											| dateLocal('default', {
												timeZone: 'UTC',
											})
									}}</span>
									<small v-else class="text-neutral-300">-</small>
								</td>

								<td v-if="tableColumns.includes('address')">
									<span v-if="person.address_json">{{ formatAddress(person.address_json) }}</span>
									<small v-else class="text-neutral-300">-</small>
								</td>

								<td v-if="j.features.includes('issues') && tableColumns.includes('threads')">
									<span v-if="person.jurisdiction_person[0].count_threads">{{
										person.jurisdiction_person[0].count_threads.toLocaleString()
									}}</span>
									<small v-else class="text-neutral-300">-</small>
								</td>
								<td v-if="j.features.includes('forms') && tableColumns.includes('form_requests')">
									<strong v-if="person.count_form_requests">{{
										person.count_form_requests.toLocaleString()
									}}</strong>
									<small v-else class="text-neutral-300">-</small>
								</td>
								<td v-if="j.features.includes('venues') && tableColumns.includes('venue_bookings')">
									<strong v-if="person.count_venue_bookings">{{
										person.count_venue_bookings.toLocaleString()
									}}</strong>
									<small v-else class="text-neutral-300">-</small>
								</td>
								<td v-if="paymentsFeature && tableColumns.includes('payments')">
									<strong v-if="person.count_payments">{{
										person.count_payments.toLocaleString()
									}}</strong>
									<small v-else class="text-neutral-300">-</small>
								</td>

								<td>
									{{ person.jurisdiction_person[0].created_at | dateLocal }}
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr v-if="states.people === 'loading'">
								<td :colspan="4 + tableColumns.length" class="text-center text-muted">
									<span class="spinner-border spinner-border-sm"></span> Loading..
								</td>
							</tr>
						</tfoot>
					</table>
				</div>

				<!-- Pagination -->
				<div v-if="states.people === 'loaded'" class="row align-items-center">
					<div class="col-auto">
						{{ (pag.page - 1) * pag.limit + 1 }}-{{ Math.min(pag.total, pag.limit * pag.page) }} of
						{{ pag.total }}
					</div>
					<div class="col">
						<nav aria-label="People list navigation">
							<ul class="pagination my-0 justify-content-center">
								<li class="page-item" :class="{ disabled: pag.page <= 1 }">
									<button class="page-link" @click="pag.page--">
										Previous
									</button>
								</li>
								<li class="page-item">
									<button class="page-link" @click="pag.page++">
										Next
									</button>
								</li>
							</ul>
						</nav>
					</div>

					<div class="col-auto">
						<label for="pag-limit" class="me-3 text-neutral-400">Per page</label>
						<div class="d-inline-flex">
							<select id="pag-limit" class="form-select form-select-sm" v-model="pag.limit">
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="modal-send-message" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<form @submit.prevent="sendMessage">
						<div class="modal-header">
							<h5 class="modal-title">Send a blast message</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-group mb-3">
								<label class="form-label" for="message-message">Message</label>
								<textarea
									v-model="message.message"
									class="form-control"
									id="message-message"
									rows="3"
									placeholder="Type a nice message 😀"
								></textarea>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="message-channels">Channels</label><br />

								<div class="form-check form-check-inline">
									<input
										class="form-check-input"
										type="checkbox"
										id="inlineCheckbox1"
										value="email"
										checked
									/>
									<label class="form-check-label" for="inlineCheckbox1">Email</label>
								</div>
								<div class="form-check form-check-inline">
									<input
										class="form-check-input"
										type="checkbox"
										id="inlineCheckbox2"
										value="sms"
										checked
									/>
									<label class="form-check-label" for="inlineCheckbox2">Text</label>
								</div>
								<div class="form-check form-check-inline">
									<input
										class="form-check-input"
										type="checkbox"
										id="inlineCheckbox3"
										value="push"
										checked
									/>
									<label class="form-check-label" for="inlineCheckbox3">Push notification</label>
								</div>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="message-message">Message preview</label>
								<div class="row">
									<div class="col">
										<p class="lead">Text/SMS</p>
										<p>
											<img
												src="https://photos5.appleinsider.com/gallery/33518-59021-32150-54773-imessage-bug-header-l-l.jpg"
												class="img-fluid"
											/>
										</p>
									</div>
									<div class="col">
										<p class="lead">Push notification</p>
										<p>
											<img
												src="https://support.ajax.systems/wp-content/uploads/2019/05/push-800x420.jpg"
												class="img-fluid"
											/>
										</p>
									</div>
									<div class="col">
										<p class="lead">Email preview</p>
										<p>[preview here]</p>
									</div>
								</div>
							</div>
						</div>

						<div class="modal-footer text-end">
							<button class="btn btn-primary">Send Message</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Vue from 'vue'
import { debounce } from 'vue-debounce'

import heyGovApi, { handleResponseError } from '@/api.js'
import { formatAddress } from '@/utils.js'

//import PersonLink from '@/components/PersonLink.vue'
import PersonAvatar from '@/components/PersonAvatar.vue'

export default {
	name: 'People',
	components: { PersonAvatar },
	data() {
		return {
			states: {
				people: 'loading',
				dev: false,
			},
			pag: {
				page: 1,
				limit: Number(localStorage.getItem('hg-people-limit') || 50),
				total: 0,
			},
			sorting: {
				order_by: localStorage.getItem('hg-people-order-by') || 'created_at',
				order: localStorage.getItem('hg-people-order') || 'desc',
			},
			filters: {
				q: this.$route.query.q || '',
				role: this.$route.query.role || null,
				department_id: null,
			},
			tableColumns: (
				localStorage.getItem('hg-people-columns') || 'email,phone,threads,form_requests,venue_bookings,payments'
			).split(','),

			stats: null,

			people: [],
			peopleReqAbortController: null,
			peopleSelected: [],
			message: {
				message: '',
			},
		}
	},
	computed: {
		...mapState(['j', 'roles', 'departments']),
		...mapGetters(['currentRole', 'activeDepartments']),
		paymentsFeature() {
			return (
				this.j.features.includes('payments') ||
				this.j.features.includes('forms') ||
				this.j.features.includes('venues')
			)
		},
		loadPeopleDebounced() {
			return debounce(this.loadPeople, 250)
		},
	},
	created() {
		if (this.currentRole === 'ADMIN') {
			this.$store.dispatch('loadDepartments')
			this.loadPeople()
			//this.loadStats()
		} else {
			this.$router.push('/')
		}
	},
	methods: {
		formatAddress,

		loadPeople() {
			// cancel previous request, if not finished yet
			if (this.peopleReqAbortController) {
				this.peopleReqAbortController.abort()
			}

			this.states.people = 'loading'

			// create new request signal
			this.peopleReqAbortController = new AbortController()

			const params = {
				...this.filters,
				...this.sorting,
				limit: this.pag.limit,
				page: this.pag.page,
			}

			heyGovApi.get(`/${this.j.slug}/people/new`, { params, signal: this.peopleReqAbortController.signal }).then(
				({ data, headers }) => {
					this.pag.total = Number(headers['x-total'] || 0)
					this.people = data.map(person => {
						const roles = person.jurisdiction_person.map(jp => jp.role)

						// determine highest role
						if (roles.includes('ADMIN')) {
							person.role = 'ADMIN'
						} else if (roles.includes('EDITOR')) {
							person.role = 'EDITOR'
						} else if (roles.includes('WORKER')) {
							person.role = 'WORKER'
						} else {
							person.role = 'CITIZEN'
						}

						return person
					})

					this.states.people = 'loaded'
				},
				error => {
					handleResponseError('Error loading people ({error})')(error)
					this.states.people = 'error'
				}
			)
		},

		loadStats() {
			heyGovApi.get(`/${this.j.slug}/people/stats`).then(({ data }) => {
				this.stats = data
			}, handleResponseError('Error loading stats ({error})'))
		},

		sortTable(orderBy, defaultOrder = 'desc') {
			if (this.sorting.order_by === orderBy) {
				// if the same column is clicked, reverse the sort order
				this.sorting.order = this.sorting.order === 'asc' ? 'desc' : 'asc'
			} else {
				// if a new column is clicked, start with the default order
				this.sorting.order = defaultOrder
			}

			this.sorting.order_by = orderBy
		},
		loadPeopleFirstPage() {
			if (this.pag.page != 1) {
				this.pag.page = 1
			} else {
				this.people = []
				this.loadPeople()
			}
		},

		sendMessage() {
			console.log('TODO', 'send message to', this.peopleSelected)
			alert(`TBD send message to ${this.peopleSelected.join(',')}`)
		},
		speakMessage() {
			var msg = new SpeechSynthesisUtterance()
			msg.text = this.message.message
			window.speechSynthesis.speak(msg)
		},
		deletePeople(peopleIds) {
			if (confirm('For sure delete so many accounts?')) {
				peopleIds.forEach(id => {
					this.deletePerson(id)
				})
			}
		},
		deletePerson(id) {
			heyGovApi.delete(`/${this.j.slug}/people/${id}`).then(
				() => {
					this.people = this.people.filter(p => p.id !== id)
					Vue.toasted.error(`Person deleted from database`)
				},
				error => {
					Vue.toasted.error(`Error deleting person ~ ${error}`)
				}
			)
		},
		selectAll($event) {
			if ($event.target.checked) {
				this.peopleSelected = this.people.map(p => p.id)
			} else {
				this.peopleSelected = []
			}
		},
	},
	watch: {
		'filters.q'(q, oldQ) {
			if (oldQ !== q) {
				this.states.people = 'loading'
				this.people = []
				this.loadPeopleDebounced()
			}
		},
		'filters.role'(role, oldRole) {
			if (oldRole !== role) {
				this.loadPeopleFirstPage()
			}
		},
		'filters.department_id'(department, prevDepartment) {
			if (prevDepartment !== department) {
				this.loadPeopleFirstPage()
			}
		},
		sorting: {
			deep: true,
			handler() {
				this.loadPeopleFirstPage()
				localStorage.setItem('hg-people-order-by', this.sorting.order_by)
				localStorage.setItem('hg-people-order', this.sorting.order)
			},
		},
		'pag.page'() {
			this.people = []
			this.loadPeople()
		},
		'pag.limit'() {
			this.people = []
			this.loadPeople()
			localStorage.setItem('hg-people-limit', this.pag.limit)
		},
		tableColumns() {
			localStorage.setItem('hg-people-columns', this.tableColumns.join(','))
		},
	},
}
</script>
